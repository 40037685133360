<template>
    <div class="d-flex flex-column-fluid">
        <!--begin::Container-->
        <div class="container-fluid py-8">
            <div class="d-flex flex-row">
                <!--begin::Content-->
                <div class="flex-row-fluid ">
                    <div class="row">
                        <div class="col-lg-12">
                            <!--begin::Card-->
                            <div class="card card-custom gutter-b example example-compact">
                                <div class="card-header">
                                    <h3 class="card-title">Free Trial Signup</h3>
                                </div>
                                <!--begin::Form-->
                                <form class="form" novalidate="novalidate" id="kt_save_trial_form">
                                    <div class="card-body">
                                        <h3 class="font-size-lg text-dark font-weight-bold mb-6">1. Credentials:</h3>
                                        <div class="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Email: <span class="text-danger">*</span></label>
                                                    <input type="email" name="email" ref="email" class="form-control"
                                                        placeholder="Enter the email address" v-model="form.email"
                                                        autocomplete="off" />
                                                    <span class="form-text text-dark">Please enter the email
                                                        address</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Password: <span class="text-danger">*</span></label>
                                                    <input type="password" name="password" ref="password"
                                                        class="form-control" placeholder="Enter the password"
                                                        v-model="form.password" autocomplete="off" />
                                                    <span class="form-text text-dark">If the account already exists
                                                        this will
                                                        be ignored</span>
                                                </div>
                                            </div>
                                        </div>
                                        <h3 class="font-size-lg text-dark font-weight-bold mb-6">2. Personal Info:</h3>
                                        <div class="mb-15">
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>First Name: <span class="text-danger">*</span></label>
                                                    <input type="text" name="firstname" ref="firstname"
                                                        class="form-control" placeholder="Enter the first name"
                                                        v-model="form.firstname" />
                                                    <span class="form-text text-dark">Please enter the first
                                                        name</span>
                                                </div>
                                                <div class="col-lg-6">
                                                    <label>Location:</label>
                                                    <div class="input-group">
                                                        <input type="text" name="city" ref="city" class="form-control"
                                                            placeholder="Denver, CO, USA" v-model="form.city" />
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">
                                                                <i class="la la-map-marker"></i>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <span class="form-text text-dark">Please enter the city, state and
                                                        country.</span>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <label>Expiration Date: <span class="text-danger">*</span></label>
                                                    <b-form-datepicker class="form-control mb-2" multiple
                                                        name="expirationdate" ref="expirationdate" id="expirationdate"
                                                        v-model="form.expirationdate">
                                                    </b-form-datepicker>
                                                </div>
                                            </div>
                                            <div class="form-group row">
                                                <div class="col-lg-6">
                                                    <div class="image-input image-input-outline" id="kt_profilephoto">
                                                        <label class="mb-5">Profile Image:</label>
                                                        <div class="image-input-wrapper"
                                                            :style="{ backgroundImage: `url(${profilephoto})`, width: `200px`, height: `200px` }">
                                                        </div>
                                                        <label class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                    mt-35
                                                " data-action="change" data-toggle="tooltip" title=""
                                                            data-original-title="Change profile photo">
                                                            <i class="fa fa-pen icon-sm text-dark"></i>
                                                            <input type="file" name="profilephoto"
                                                                accept=".png, .jpg, .jpeg"
                                                                @change="onProfileFileChange($event)" />
                                                            <input type="hidden" name="profile_profilephoto_remove" />
                                                        </label>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="cancel" data-toggle="tooltip"
                                                            title="Cancel profile photo">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                        <span class="
                                                    btn
                                                    btn-xs
                                                    btn-icon
                                                    btn-circle
                                                    btn-white
                                                    btn-hover-text-primary
                                                    btn-shadow
                                                " data-action="remove" data-toggle="tooltip"
                                                            title="Remove profile photo" @click="current_profilephoto = null; form.profilephoto = null;
                                                            ">
                                                            <i class="ki ki-bold-close icon-xs text-dark"></i>
                                                        </span>
                                                    </div>
                                                    <span class="form-text text-dark mt-5">Allowed file types: png,
                                                        jpg,
                                                        jpeg.</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card-footer">
                                        <div class="row">
                                            <div class="col-lg-6">
                                                <button ref="kt_save_changes"
                                                    class="btn btn-primary mr-2">Create</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Swal from "sweetalert2";
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";
import KTUtil from "@/assets/js/components/util";
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";
import emailAddress from "@/assets/plugins/formvalidation/dist/es6/validators/emailAddress";
import { email, minLength, required } from "vuelidate/lib/validators";

export default {
    name: 'freetrialsignup',
    data() {
        return {
            form: {
                firstname: "",
                email: "",
                password: "",
                city: "Denver, CO, USA",
                expirationdate: "",
                profilephoto: null
            },
            default_profilephoto: "/media/users/200.png",
            current_profilephoto: null,
            default_coachphoto: "/media/users/371_214.png",
            current_coachphoto: null
        };
    },
    mounted() {
        const trial_form = KTUtil.getById("kt_save_trial_form");

        this.fv = formValidation(trial_form, {
            fields: {
                firstname: {
                    validators: {
                        notEmpty: {
                            message: "First name is required",
                        },
                    },
                },
                email: {
                    validators: {
                        callback: {
                            message: "The value is not a valid email address",
                            callback: function (input) {
                                const value = input.value;
                                if (value === "") {
                                    return true;
                                }

                                // I want the value has to pass both emailAddress and regexp validators
                                return emailAddress().validate({
                                    value: value,
                                }).valid;
                            },
                        },
                        notEmpty: {
                            message: "Email is required",
                        },
                    },
                },
                password: {
                    minLength: minLength(6),
                    validators: {
                        notEmpty: {
                            message: "Password is required",
                        },
                    },
                },
                expirationdate: {
                    validators: {
                        notEmpty: {
                            message: "Expiration date is required",
                        },
                    },
                },
            },
            plugins: {
                trigger: new Trigger(),
                submitButton: new SubmitButton(),
                bootstrap: new Bootstrap(),
            },
        });

        let self = this;
        this.fv.on("core.form.valid", () => {
            // set spinner to submit button
            const submitButton = this.$refs["kt_save_changes"];
            submitButton.classList.add("spinner", "spinner-light", "spinner-right");

            this.$store.dispatch("createFreeTrial", this.form)
                .then(
                    function (value) {
                        Swal.fire({
                            title: "Account being created.",
                            text: "Refresh the users list in a few minutes.",
                            icon: "success",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                self.$router.push({ name: 'adminusers' })
                            }
                        })
                    },
                    function (error) {
                        submitButton.classList.remove(
                            "spinner",
                            "spinner-light",
                            "spinner-right"
                        );
                        Swal.fire({
                            title: "",
                            text: error,
                            icon: "error",
                            confirmButtonClass: "btn btn-secondary",
                            heightAuto: true,
                        });
                    });
        });

        this.fv.on("core.form.invalid", () => {
            Swal.fire({
                title: "",
                text: "Please, provide correct data!",
                icon: "error",
                confirmButtonClass: "btn btn-secondary",
                heightAuto: true,
            });
        });

    },
    computed: {
        ...mapGetters(["catList", "categories"]),
        profilephoto() {
            return this.current_profilephoto == null || this.current_profilephoto == "undefined"
                ? this.default_profilephoto
                : this.current_profilephoto;
        }
    },
    methods: {
        onProfileFileChange(e) {
            const file = e.target.files[0];

            if (typeof FileReader === "function") {
                const reader = new FileReader();

                reader.onload = (event) => {
                    this.current_profilephoto = event.target.result;
                    this.form.profilephoto = event.target.result;
                };

                reader.readAsDataURL(file);
            } else {
                alert("Sorry, FileReader API not supported");
            }
        }
    }
}
</script>

<style scoped>
.mt-35 {
    margin-top: 35px !important;
}
</style>
